import React from 'react'
import { useLocation, useNavigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import MDDrawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListIcon from '@material-ui/icons/List'
import PowerOffIcon from '@material-ui/icons/PowerOff'
import { setUserData } from '../../utils/storage'

const DrawerContext = React.createContext()

const useStyles = makeStyles({
  list: {
    width: 250
  }
})

function Drawer ({ isOpen, setIsOpen }) {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const handleNavigate = location => () => {
    navigate(location)
    setIsOpen(false)
  }

  const handleLogout = () => {
    setUserData(null)
    navigate('/login')
  }
  return (
    <MDDrawer open={isOpen} onClose={() => setIsOpen(false)}>
      <div
        className={classes.list}
        role='presentation'
      >
        <List>
          <ListItem
            button
            onClick={handleNavigate('/tastings')}
            selected={location.pathname.includes('/tasting')}
            disabled={location.pathname.includes('/tasting')}
          >
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary='Tastings' />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><PowerOffIcon /></ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItem>
        </List>
      </div>
    </MDDrawer>
  )
}

function DrawerContainer ({ children }) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const context = React.useMemo(() => ({ isOpen: isDrawerOpen, setIsOpen: setIsDrawerOpen }), [isDrawerOpen, setIsDrawerOpen])
  return (
    <DrawerContext.Provider value={context}>
      <Drawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
      {children}
    </DrawerContext.Provider>
  )
}

export default DrawerContainer

export const useDrawer = () => React.useContext(DrawerContext)
