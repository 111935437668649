import React from 'react'
import FacebookLogin from 'react-facebook-login'
import addSeconds from 'date-fns/addSeconds'
import { setUserData } from '../../utils/storage'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID

export default function Login ({ navigate }) {
  const handleResponse = async fbResponse => {
    try {
      const response = await fetch('/api/authorize', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          foo: 'bar',
          accessToken: fbResponse.accessToken
        })
      })
      const json = await response.json()
      const data = {
        id: fbResponse.userID,
        name: fbResponse.name,
        email: fbResponse.email,
        picture: fbResponse.picture,
        expires_in: json.expires_in,
        expires_at: addSeconds(new Date(), json.expires_in),
        access_token: json.access_token
      }
      setUserData(data)
      navigate('/')
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Box
      alignItems='center'
      display='flex'
      height='100vh'
      justifyContent='center'
    >
      <Card>
        <CardContent>
          <Typography variant='h2'>
            Speakeasy_WI Admin
          </Typography>
          <Box
            marginTop={2}
            textAlign='center'
          >
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              version='6.0'
              fields='name,email,picture'
              callback={handleResponse}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
