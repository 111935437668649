import React from 'react'
import { Router, useMatch, useNavigate } from '@reach/router'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { format, parseISO } from 'date-fns'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import Container from '@material-ui/core/Container'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import TastingSamples from './TastingSamples'
import TastingResults from './TastingResults'
import TabsPanel from './TabsPanel'
import TastingModal from '../TastingModal'

import { useDrawer } from '../Drawer'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  breadcrumbs: {
    margin: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(3)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}))

const query = gql`
query Tasting($id: ID!) {
  tasting(id: $id) {
    id,
    code,
    name,
    description,
    showResults,
    startDate,
    endDate,
    samples {
      id,
      tastingId,
      label,
      name,
      order
    }
  }
}
`

function Details ({ tasting }) {
  const classes = useStyles()
  return (
    <Card>
      <CardContent>
        <Typography variant='h2'>
          {tasting.name}
        </Typography>
        <Typography variant='body1'>
          {tasting.description}
        </Typography>
        <Divider className={classes.divider} light />
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='body1'>
            Start Date:<br />{format(parseISO(tasting.startDate), 'MM/dd/yyy')}
          </Typography>
          <Typography variant='body1'>
            End Date:<br />{format(parseISO(tasting.endDate), 'MM/dd/yyyy')}
          </Typography>
        </Box>
        <Divider className={classes.divider} light />
        <Box color={tasting.showResults ? 'success.main' : 'error.main'}>
          <Typography variant='body1'>
            {tasting.showResults ? 'Results are live' : 'Results are hidden'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default function Tasting () {
  const classes = useStyles()
  const navigate = useNavigate()
  const match = useMatch('/tasting/:tastingId/*')
  const { loading, data } = useQuery(query, { variables: { id: match.tastingId } })
  const [showTastingModal, setShowTastingModal] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const samples = loading ? Array(15).fill(1) : data.tasting.samples
  const { setIsOpen } = useDrawer()

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='menu' onClick={() => setIsOpen(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            Tasting Details
          </Typography>
          <IconButton
            onClick={event => setAnchorEl(event.currentTarget)}
            color='inherit'
          >
            <MoreVert />
          </IconButton>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setShowTastingModal(true)
                setAnchorEl(null)
              }}
            >
              Edit Details
            </MenuItem>
          </Menu>
        </Toolbar>
        <TabsPanel tastingId={match.tastingId} />
      </AppBar>
      <Breadcrumbs className={classes.breadcrumbs} aria-label='breadcrumb'>
        <Link color='inherit' onClick={() => navigate('/tastings')}>
          Tastings
        </Link>
        <Typography color='textPrimary'>Details</Typography>
      </Breadcrumbs>
      <Container maxWidth='xl'>
        {loading
          ? <h1>loading</h1>
          : <Details tasting={data.tasting} />}
        <Router primary={false}>
          <TastingSamples path='/' samples={samples} loading={loading} tastingId={match.tastingId} />
          <TastingResults path='results' />
        </Router>
      </Container>
      <TastingModal
        tasting={data && data.tasting}
        isOpen={showTastingModal}
        handleClose={() => setShowTastingModal(false)}
      />
    </div>
  )
}
