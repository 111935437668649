import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: theme.spacing(2)
  },
  media: {
    height: 360
  }
}))

export default function ResultCard ({ loading, participant }) {
  const classes = useStyles()

  return (
    <Grid item xs={12} md={6} lg={4} xl={3} className={classes.grid}>
      <Card className={classes.root}>
        {loading
          ? (
            <Box height={360} bgcolor='grey.100' />
          ) : (
            <CardMedia
              className={classes.media}
              image={participant.profilePicture || 'https://pbs.twimg.com/profile_images/859119644191449089/DSRq9jCp_400x400.jpg'}
              title='Contemplative Reptile'
            />
          )}
        <CardContent>
          {loading ? (
            <>
              <Skeleton animation='wave' varient='rect' height={36} width='30%' />
              <Skeleton animation='wave' varient='rect' height={18} width='100%' />
            </>
          ) : (
            <>
              <Typography gutterBottom variant='h5' component='h2'>
                {participant.name}
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                {participant.notes && participant.notes.length} samples tasted
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}
