import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

export default function SampleCard ({ handleEditClick, hidden, loading, sample }) {
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Card>
        <Box
          color='secondary.contrastText'
          bgcolor='secondary.main'
          height={200}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Typography variant='h2' component='h3'>
            {sample.label}
          </Typography>
        </Box>
        <CardContent>
          {loading ? (
            <>
              <Skeleton animation='wave' varient='rect' height={36} width='30%' />
            </>
          ) : (
            <>
              <Typography gutterBottom variant='h5' component='h2'>
                {hidden ? 'Hidden' : sample.name}
              </Typography>
            </>
          )}
        </CardContent>
        <CardActions>
          {loading ? (
            <>
              <Skeleton animation='wave' varient='rect' height={16} width={48} />
            </>
          ) : (
            <>
              <Button size='small' color='primary' onClick={() => handleEditClick(sample)}>
                Edit
              </Button>
            </>
          )}
        </CardActions>
      </Card>
    </Grid>
  )
}
