import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { format, parseISO } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import { Formik, Form, Field } from 'formik'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Switch from '@material-ui/core/Switch'
import * as yup from 'yup'

const tastingsQuery = gql`
 {
   tastings {
     id
     name
     description
   }
 }
`

const createTastingMutation = gql`
mutation createTasting($code: String!, $name: String!, $description: String!, $startDate: Date!, $endDate: Date!) {
  tasting: createTasting(code: $code, name: $name, description: $description, startDate: $startDate, endDate: $endDate) {
    id,
    code,
    name,
    description,
    showResults,
    startDate,
    endDate
  }
}
`

const updateTastingMutation = gql`
mutation updateTasting($id: ID!, $name: String!, $description: String!, $startDate: Date!, $endDate: Date!, $showResults: Boolean!) {
  tasting: updateTasting(id: $id, name: $name, description: $description, startDate: $startDate, endDate: $endDate, showResults: $showResults) {
    id,
    code,
    name,
    description,
    showResults,
    startDate,
    endDate
  }
}
`

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  code: yup.string().required(),
  showResults: yup.boolean().required()
})

const getInitialValues = tasting => {
  if (tasting) {
    return {
      name: tasting.name,
      description: tasting.description,
      startDate: parseISO(tasting.startDate),
      endDate: parseISO(tasting.endDate),
      code: tasting.code,
      showResults: tasting.showResults
    }
  } else {
    return {
      name: '',
      description: '',
      startDate: new Date(),
      endDate: new Date(),
      code: '',
      showResults: false
    }
  }
}

export default function TastingModal ({ handleClose, isOpen, tasting }) {
  const initialValues = getInitialValues(tasting)

  const [createTasting, { loading: createLoading, error: createError }] = useMutation(
    createTastingMutation,
    {
      errorPolicy: 'all',
      update: (cache, { data: { tasting } }) => {
        const { tastings } = cache.readQuery({ query: tastingsQuery })
        const newTastings = [tasting, ...tastings]
        cache.writeQuery({ query: tastingsQuery, data: { tastings: newTastings } })
        handleClose()
      }
    }
  )
  const [updateTasting, { loading: updateLoading, error: updateError }] = useMutation(updateTastingMutation, {
    errorPolicy: 'all',
    onCompleted: () => {
      handleClose()
    }
  })

  const onSubmit = values => {
    const variables = {
      ...values,
      startDate: format(values.startDate, 'yyyy-MM-dd'),
      endDate: format(values.endDate, 'yyyy-MM-dd')
    }
    if (tasting) {
      variables.id = tasting.id
      updateTasting({ variables })
    } else {
      createTasting({ variables })
    }
  }

  console.log({ createError, updateError })
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby='form-dialog-title'>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid }) => (
            <Form>
              <DialogTitle id='form-dialog-title'>Tasting</DialogTitle>
              <DialogContent>
                <DialogContentText>
                 Please fill out the information for the tasting
                </DialogContentText>
                <Field name='name'>
                  {({ field, meta }) => (
                    <TextField
                      margin='dense'
                      label='Name'
                      required
                      error={meta.touched && !!meta.error}
                      fullWidth
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
                <Field name='description'>
                  {({ field, meta }) => (
                    <TextField
                      margin='dense'
                      id='description'
                      label='Description'
                      required
                      error={meta.touched && !!meta.error}
                      multiline
                      rows='4'
                      fullWidth
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
                <Box display='flex' justifyContent='between'>
                  <Field name='startDate'>
                    {({ field, form, meta }) => (
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='start-date'
                        label='Start Date'
                        error={!!meta.error}
                        helperText={!!meta.error && 'Start Date is required'}
                        value={field.value}
                        onChange={value => {
                          form.setFieldValue('startDate', value)
                        }}
                      />
                    )}
                  </Field>
                  <Field name='endDate'>
                    {({ field, form, meta }) => (
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='end-date'
                        label='End Date'
                        error={!!meta.error}
                        helperText={!!meta.error && 'End Date is required'}
                        value={field.value}
                        onChange={value => {
                          form.setFieldValue('endDate', value)
                        }}
                      />
                    )}
                  </Field>
                </Box>
                <Field name='code'>
                  {({ field, meta }) => (
                    <TextField
                      margin='dense'
                      label='Code'
                      required
                      disabled={!!tasting}
                      error={meta.touched && !!meta.error}
                      fullWidth
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
                <Field name='showResults'>
                  {({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch checked={field.value} {...field} />
                      }
                      label='Show Results'
                    />
                  )}
                </Field>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color='secondary'>
                  Cancel
                </Button>
                <Button disabled={createLoading || updateLoading || !dirty || !isValid} type='submit' color='primary'>
                  {tasting ? 'Update' : 'Create'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </MuiPickersUtilsProvider>
  )
}
