import React from 'react'
import { Redirect } from '@reach/router'
import isAfter from 'date-fns/isAfter'
import { getUserData } from '../../utils/storage'
import DrawerContainer from '../Drawer'

export default function Container ({ children }) {
  const userData = getUserData()
  const isAuthenticated = userData && isAfter(Date.parse(userData.expires_at), new Date())
  if (!isAuthenticated) {
    return <Redirect to='/login' noThrow />
  }
  return (
    <div>
      <DrawerContainer>
        {children}
      </DrawerContainer>
    </div>
  )
}
