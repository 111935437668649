import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { Redirect, Router } from '@reach/router'
import CssBaseline from '@material-ui/core/CssBaseline'
import Login from './components/Login'
import Tastings from './components/Tastings'
import Tasting from './components/Tasting'
import Container from './components/Container'
import client from './graphql/client'

export default function App () {
  return (
    <ApolloProvider client={client}>
      <CssBaseline />
      <Router>
        <Login path='/login' />
        <Container path='/'>
          <Redirect from='/' to='/tastings' noThrow />
          <Tastings path='/tastings' />
          <Tasting path='/tasting/:tastingId/*' />
        </Container>
      </Router>
    </ApolloProvider>
  )
}
