import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import TastingCard from './TastingCard'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import TastingModal from '../TastingModal'
import { useDrawer } from '../Drawer'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  grid: {
    marginTop: theme.spacing(3)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5)
  }
}))

const query = gql`
 {
   tastings {
     id
     name
     description
   }
 }
`

export default function Tastings () {
  const classes = useStyles()
  const { loading, data } = useQuery(query)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { setIsOpen } = useDrawer()

  const dummy = Array(12).fill(4)

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={() => setIsOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            Tastings
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth='xl'>
        <Grid className={classes.grid} container spacing={3}>
          {loading && dummy.map((_val, idx) => <TastingCard key={idx} loading />)}
          {data && data.tastings.map(tasting => <TastingCard key={tasting.id} tasting={tasting} />)}
        </Grid>
      </Container>
      <Fab className={classes.fab} color='primary' aria-label='add' onClick={() => setIsModalOpen(true)}>
        <AddIcon />
      </Fab>
      <TastingModal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} />
    </div>
  )
}
