import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import SampleCard from './SampleCard'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import SampleModal from '../SampleModal'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  grid: {
    marginTop: theme.spacing(3)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5)
  },
  switch: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}))

export default function TastingSamples ({ loading, samples, tastingId }) {
  const classes = useStyles()
  const [hidden, setHidden] = React.useState(true)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [sampleToEdit, setSampleToEdit] = React.useState(null)
  const handleEditClick = sample => {
    setSampleToEdit(sample)
    setIsModalOpen(true)
  }
  const handleModalClose = () => {
    setSampleToEdit(null)
    setIsModalOpen(false)
  }
  return (
    <>
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch checked={!hidden} onChange={e => setHidden(!e.target.checked)} />
        }
        label='Show Names'
      />
      <Grid container spacing={3}>
        {samples.map((sample, idx) =>
          <SampleCard
            key={idx}
            loading={loading}
            sample={sample}
            hidden={hidden}
            handleEditClick={handleEditClick}
          />
        )}
      </Grid>
      <Fab className={classes.fab} color='primary' aria-label='add' onClick={() => setIsModalOpen(true)}>
        <AddIcon />
      </Fab>
      <SampleModal
        sample={sampleToEdit}
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        tastingId={tastingId}
      />
    </>
  )
}
