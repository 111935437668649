import React from 'react'
import { useLocation, useNavigate } from '@reach/router'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

function LinkTab (props) {
  const navigate = useNavigate()
  return (
    <Tab
      component='a'
      onClick={event => {
        event.preventDefault()
        navigate(props.href)
      }}
      {...props}
    />
  )
}

export default function TabsPanel ({ tastingId }) {
  const location = useLocation()
  const [value, setValue] = React.useState(location.pathname.includes('results') ? 1 : 0)

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }
  return (
    <Tabs
      variant='fullWidth'
      value={value}
      onChange={handleChange}
    >
      <LinkTab label='Samples' href={`/tasting/${tastingId}`} />
      <LinkTab label='Results' href={`/tasting/${tastingId}/results`} />
    </Tabs>
  )
}
