import ApolloClient from 'apollo-boost'
import { getUserData } from '../utils/storage'

const client = new ApolloClient({
  request: operation => {
    const userData = getUserData()
    operation.setContext({
      headers: {
        access_token: userData.access_token
      }
    })
  }
})

export default client
