import React from 'react'
import { useMatch } from '@reach/router'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import Grid from '@material-ui/core/Grid'
import ResultCard from './ResultCard'

const query = gql`
query tastingResults($id: ID!) {
  tasting(id: $id) {
    id,
    participants {
      id,
      name,
      profilePicture
      notes {
        id,
        color,
        nose,
        taste,
        guess,
        rating,
        sample {
          name
        }
      }
    }
  }
}
`

export default function TastingSamples () {
  const match = useMatch('/tasting/:tastingId/*')
  const { loading, data } = useQuery(query, { variables: { id: match.tastingId } })
  const participants = loading ? Array(15).fill(1) : data.tasting.participants
  return (
    <Grid container spacing={3}>
      {participants.map((participant, idx) =>
        <ResultCard
          key={idx}
          loading={loading}
          participant={participant}
        />
      )}
    </Grid>
  )
}
