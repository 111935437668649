import React from 'react'
import { useNavigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(theme => ({
  content: {
    height: 100,
    overflow: 'auto'
  }
}))

export default function TastingCard ({ loading, tasting }) {
  const navigate = useNavigate()
  const classes = useStyles()
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Card>
        <CardContent>
          {loading ? (
            <>
              <Skeleton animation='wave' varient='rect' height={36} width='30%' />
              <Skeleton animation='wave' varient='rect' height={16} />
              <Skeleton animation='wave' varient='rect' height={16} />
              <Skeleton animation='wave' varient='rect' height={16} width='85%' />
            </>
          ) : (
            <>
              <Typography gutterBottom variant='h5' component='h2'>
                {tasting.name}
              </Typography>
              <Typography className={classes.content} variant='body2' color='textSecondary' component='p'>
                {tasting.description}
              </Typography>
            </>
          )}
        </CardContent>
        <CardActions>
          {loading ? (
            <>
              <Skeleton animation='wave' varient='rect' height={16} width={48} />
              <Skeleton animation='wave' varient='rect' height={16} width={48} />
            </>
          ) : (
            <>
              <Button size='small' color='primary' onClick={() => navigate(`/tasting/${tasting.id}`)}>
                view
              </Button>
            </>
          )}
        </CardActions>
      </Card>
    </Grid>
  )
}
