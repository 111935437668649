/* global localStorage */
const USER_DATA = 'USER_DATA'

export const getUserData = () => {
  return JSON.parse(localStorage.getItem(USER_DATA))
}

export const setUserData = userData => {
  localStorage.setItem(USER_DATA, JSON.stringify(userData))
}
