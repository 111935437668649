import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

const createSampleMutation = gql`
mutation createSample($tastingId: ID!, $label: String!, $name: String!, $order: Int!) {
  sample: createSample(tastingId: $tastingId, label: $label, name: $name, order: $order) {
    id,
    tastingId,
    label,
    name,
    order
  }
}
`

const updateSampleMutation = gql`
mutation updateSample($id: ID!, $label: String!, $name: String!, $order: Int!) {
  sample: updateSample(id: $id, label: $label, name: $name, order: $order) {
    id,
    tastingId,
    label,
    name,
    order
  }
}
`

const schema = yup.object().shape({
  label: yup.string().required(),
  name: yup.string().required(),
  order: yup.number().integer().required()
})

const getInitialValues = sample => {
  if (sample) {
    return {
      label: sample.label,
      name: sample.name,
      order: sample.order
    }
  } else {
    return {
      label: '',
      name: '',
      order: ''
    }
  }
}

export default function SampleModal ({ handleClose, isOpen, sample, tastingId }) {
  const initialValues = getInitialValues(sample)

  const [createSample, { loading: createLoading }] = useMutation(
    createSampleMutation,
    {
      update: (cache, { data: { sample } }) => {
        const fragment = gql`
          fragment currentTasting on Tasting {
            id
            samples {
              id
              label
              name
              order
            }
          }
        `
        const tasting = cache.readFragment({ id: `Tasting:${tastingId}`, fragment })
        const newSamples = [...tasting.samples, sample]
        cache.writeFragment({
          id: `Tasting:${tastingId}`,
          fragment,
          data: {
            __typename: 'Tasting',
            id: tastingId,
            samples: newSamples
          }
        })
        handleClose()
      }
    }
  )
  const [updateSample, { loading: updateLoading }] = useMutation(updateSampleMutation, {
    onCompleted: () => {
      handleClose()
    }
  })

  const onSubmit = values => {
    const variables = {
      ...values,
      tastingId
    }
    if (sample) {
      variables.id = sample.id
      updateSample({ variables })
    } else {
      createSample({ variables })
    }
  }
  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid }) => (
          <Form>
            <DialogTitle id='form-dialog-title'>Sample</DialogTitle>
            <DialogContent>
              <DialogContentText>
                  Please fill out the information for the sample
              </DialogContentText>
              <Field name='label'>
                {({ field, meta }) => (
                  <TextField
                    margin='dense'
                    label='Label'
                    required
                    fullWidth
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
              <Field name='name'>
                {({ field, meta }) => (
                  <TextField
                    margin='dense'
                    label='Name'
                    required
                    fullWidth
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
              <Field name='order'>
                {({ field, meta }) => (
                  <TextField
                    margin='dense'
                    label='Order'
                    type='number'
                    required
                    fullWidth
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color='secondary'>
                  Cancel
              </Button>
              <Button disabled={createLoading || updateLoading || !dirty || !isValid} type='submit' color='primary'>
                {sample ? 'Update' : 'Create'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
